import React, { useEffect } from 'react';
import { Group, Circle, Rect, Text } from 'react-konva';
import { Spring, animated } from 'react-spring/renderprops-konva';
//import { useSpring } from 'react-spring';

const Card = props => {

  //const [animation, setAnimation, stopAnimation] = useSpring(() => ({from:{x: 200,y:200}, to:{x: props.x || 0, y:props.y || 0}}));

  useEffect(()=>{
    //console.log("props change", props.x, props.y, animation);
    //if(props.x && props.y)setAnimation({to:{x: props.x, y: props.y}});
  }, [props.x, props.y])

  return (//from={props.from} to={props.to}
    <Spring native from={props.from} to={{x:props.x || 0, y:props.y || 0}} reset={props.reset} immediate={props.immediate} delay={props.delay} onRest={props.onRest}>
      {(prps)=>(
        <animated.Group {...prps}  onClick={props.onClick}>
          <Rect x={0} y={0} width={70} height={100} fill="grey" cornerRadius={10} from={{x:0, y:0}} to={{x:Math.random()*100, y: 100}}/>
          {props.data.cost && <Text text={((props.data.cost.build)?"🧱 "+props.data.cost.build:"")+((props.data.cost.magic)?"💫 "+props.data.cost.magic:"")+((props.data.cost.attack)?"🗡 "+props.data.cost.attack:"")} x={0} y={0} fontSize={15} width={70} align="center"/>}
          <Text text={props.data.name?props.data.name:"Ime Karte"} x={0} y={30} fontSize={15} height={40} width={70} align="center"/>
          <Text text={(props.data.value)?"⚔️ "+props.data.value:"?"} x={0} y={80} fontSize={15} width={70} align='center'/>
        </animated.Group>
      )}
    </Spring>
  )
}

export default Card
