import React, { useState, useContext, useEffect} from 'react';
import { Avatar, Whisper, Tooltip } from 'rsuite';
import { CentralContext } from "../../context";
import userService from "../../services/user.service";
import Options from "./options";

const LargePic = props => {
  const cntx = useContext(CentralContext);
  const [img, setImg] = useState(props.fallback);
  const triggerRef = React.createRef();

  useEffect(()=>{
    //console.log("is premium", cntx.user.premium);
    if(cntx.user.premium){
      userService.getLargePicture(props?.player?.id || props.id)
      .then(pic=>{
        //console.log("large pic", pic);
        if(pic.url)setImg(pic.url)
      })
      .catch(e=>console.error(e))
    } else {
      if(props.fallback)setImg(props.fallback)
      else {
        userService.getPicture(props?.player?.id || props.id)
        .then(pic=>{
          //console.log("mini pic", pic.url);
          if(pic.url)setImg(pic.url)
        })
        .catch(e=>console.error(e))
      }
    }
  }, [])

  return (
    props.avatar ?
     props.mobile ?
      <Avatar src={img} size={cntx.user.premium?"lg":"md"}/>
     :
      <Whisper trigger="click" placement="bottom" triggerRef={triggerRef} speaker={<Options content={props.player} onSelect={(key, data)=>{props.setSelectedOption(key, data);triggerRef.current.close()}}/>}>
        <div>
          <Whisper
            trigger="hover"
            placement="bottom"
            speaker={
              <Tooltip>
                {props.tooltip}
              </Tooltip>
            }>
            <Avatar src={img} size={cntx.user.premium?"lg":"md"}/>
          </Whisper>
        </div>
      </Whisper>
    :
      <Whisper
        trigger="hover"
        placement="bottom"
        speaker={
          <Tooltip>
            {props.tooltip}
          </Tooltip>
        }>
        <img src={img}/>
      </Whisper>
  )
}

export default LargePic;
