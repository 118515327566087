import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Whisper,
  Tooltip
} from 'rsuite';
import {
  screenSize
} from "../../hooks";
import socket from "../../socket";
import styles from "./index.module.css";
import Options from "../Chat/options";

const InviteList = props => {
  const { height, width, mobile } = screenSize();

  const [players, setPlayers] = useState([]);

  useEffect(()=>{
    socket.setupListener("inviteList", players=>{
      //console.log(players);
      const uniq = new Map();
      players.forEach((player) => {
        uniq.set(player.id, player);
      });
      setPlayers([...uniq.values()]);
    })
    socket.emit("inviteList");
  }, [])

  const removeFromList = player => {
    //console.log("remove player from list", player);
    setPlayers((players)=>{
      const uniq = new Map();
      players.forEach((p) => {
        if(p.id!=player.id)uniq.set(p.id, p);
      });
      setPlayers([...uniq.values()])
    });
  }

  const invitePlayer = (key, data) => {
    //console.log("invite", key, data);
    socket.emit("inviteToRoom", {invite: data.id})
  }

  return (
    <div className={styles.inviteList} style={(props.mobile?{width:"100vw"}:null)}>
      {!!(players && players.length) && players.map(player=>{
        const triggerRef = React.createRef();
        return (
          <Whisper trigger="click" placement="bottom" triggerRef={triggerRef} speaker={<Options content={player} invite={true} onSelect={(key, data)=>{invitePlayer(key, data);triggerRef.current.close()}}/>}>
            <div className={styles.avatar} key={player.id}>
            <Whisper
              trigger="hover"
              placement="bottom"
              speaker={
                <Tooltip>
                  {player.name}
                </Tooltip>
              }>
              <Avatar src={player.picture} />
            </Whisper>
            </div>
          </Whisper>
        )
      })}
    </div>
  )
}

export default InviteList
