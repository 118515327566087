import React, { useState, useEffect } from 'react';
import { Group, Circle, Rect, Text } from 'react-konva';

const Castle = props => {

  const [colors, setColors] = useState({
    red: {
      color: "red",
      castle: {
        x: 0,
        y: 0,
        width: 100,
        height: 200,
        value: 30
      },
      wall: {
        x: 120,
        y: 0,
        width: 10,
        height: 200,
        value: 10
      },
      txt: {
        fontSize: 20,
        width: 100,
        align: "left",
        builders: {
          x: 0,
          y: 0,
          value: 444444,
          icon: "🔨"
        },
        bricks: {
          x: 0,
          y: 30,
          value: 5,
          icon: "🧱"
        },
        mages: {
          x: 0,
          y: 60,
          value: 5,
          icon: "🧙"
        },
        magic: {
          x: 0,
          y: 90,
          value: 5,
          icon: "💫"
        },
        troops: {
          x: 0,
          y: 120,
          value: 5,
          icon: "💂"
        },
        attack: {
          x: 0,
          y: 150,
          value: 5,
          icon: "🗡"
        }
      }
    },
    blue: {
      color: "blue",
      castle: {
        x: 400,
        y: 0,
        width: 100,
        height: 200,
        value: 30
      },
      wall: {
        x: 380,
        y: 0,
        width: 10,
        height: 200,
        value: 10
      },
      txt: {
        fontSize: 20,
        width: 100,
        align: "right",
        builders: {
          x: 400,
          y: 0,
          value: 444444,
          icon: "🔨"
        },
        bricks: {
          x: 400,
          y: 30,
          value: 5,
          icon: "🧱"
        },
        mages: {
          x: 400,
          y: 60,
          value: 5,
          icon: "🧙"
        },
        magic: {
          x: 400,
          y: 90,
          value: 5,
          icon: "💫"
        },
        troops: {
          x: 400,
          y: 120,
          value: 5,
          icon: "💂"
        },
        attack: {
          x: 400,
          y: 150,
          value: 5,
          icon: "🗡"
        }
      }
    },
    green: {
      color: "green",
      castle: {
        x: 0,
        y: 300,
        width: 100,
        height: 200,
        value: 30
      },
      wall: {
        x: 120,
        y: 300,
        width: 10,
        height: 200,
        value: 10
      },
      txt: {
        fontSize: 20,
        width: 100,
        align: "left",
        builders: {
          x: 0,
          y: 310,
          value: 4,
          icon: "🔨"
        },
        bricks: {
          x: 0,
          y: 340,
          value: 5,
          icon: "🧱"
        },
        mages: {
          x: 0,
          y: 370,
          value: 5,
          icon: "🧙"
        },
        magic: {
          x: 0,
          y: 400,
          value: 5,
          icon: "💫"
        },
        troops: {
          x: 0,
          y: 430,
          value: 5,
          icon: "💂"
        },
        attack: {
          x: 0,
          y: 460,
          value: 5,
          icon: "🗡"
        }
      }
    },
    yellow: {
      color: "yellow",
      castle: {
        x: 400,
        y: 300,
        width: 100,
        height: 200,
        value: 30
      },
      wall: {
        x: 380,
        y: 300,
        width: 10,
        height: 200,
        value: 10
      },
      txt: {
        fontSize: 20,
        width: 100,
        align: "right",
        builders: {
          x: 400,
          y: 310,
          value: 444444,
          icon: "🔨"
        },
        bricks: {
          x: 400,
          y: 340,
          value: 5,
          icon: "🧱"
        },
        mages: {
          x: 400,
          y: 370,
          value: 5,
          icon: "🧙"
        },
        magic: {
          x: 400,
          y: 400,
          value: 5,
          icon: "💫"
        },
        troops: {
          x: 400,
          y: 430,
          value: 5,
          icon: "💂"
        },
        attack: {
          x: 400,
          y: 460,
          value: 5,
          icon: "🗡"
        }
      }
    },
  })
  //console.log("props:", props.update);
  useEffect(()=>{
    if(props?.update?.castle){
      let newColorValues = {...colors};
      newColorValues[props.color] = {...colors[props.color],
        castle: {...colors[props.color].castle, value: props.update.castle.castle},
        wall: {...colors[props.color].wall, value: props.update.castle.wall},
        txt: {...colors[props.color].txt,
          builders: {...colors[props.color].txt.builders, value: props.update.castle.builders},
          bricks: {...colors[props.color].txt.bricks, value: props.update.castle.build},
          mages: {...colors[props.color].txt.mages, value: props.update.castle.mages},
          magic: {...colors[props.color].txt.magic, value: props.update.castle.magic},
          troops: {...colors[props.color].txt.troops, value: props.update.castle.troops},
          attack: {...colors[props.color].txt.attack, value: props.update.castle.attack},
          wall: {...colors[props.color].txt.wall, value: props.update.castle.wall}
        }
      }
      setColors(newColorValues);
    }
  }, [props.update])

  return (
    <Group>
      <Rect x={colors[props.color].castle.x} y={colors[props.color].castle.y} width={colors[props.color].castle.width} height={colors[props.color].castle.height} fill="#CCCCCCAA"/>
      <Rect x={colors[props.color].castle.x} y={colors[props.color].castle.y+colors[props.color].castle.height-colors[props.color].castle.height*(colors[props.color].castle.value/100)} width={colors[props.color].castle.width} height={colors[props.color].castle.height*(colors[props.color].castle.value/100)} fill={colors[props.color].color}/>
      <Rect x={colors[props.color].wall.x} y={colors[props.color].wall.y+colors[props.color].wall.height-colors[props.color].wall.height*(colors[props.color].wall.value/100)} width={colors[props.color].wall.width} height={colors[props.color].wall.height*(colors[props.color].wall.value/100)} fill={colors[props.color].color}/>
      <Text text={(colors[props.color].txt.align=="right")?`${colors[props.color].txt.builders.value} ${colors[props.color].txt.builders.icon}`:`${colors[props.color].txt.builders.icon} ${colors[props.color].txt.builders.value}`} x={colors[props.color].txt.builders.x} y={colors[props.color].txt.builders.y} fontSize={colors[props.color].txt.fontSize} width={colors[props.color].txt.width} align={colors[props.color].txt.align}/>
      <Text text={(colors[props.color].txt.align=="right")?`${colors[props.color].txt.bricks.value} ${colors[props.color].txt.bricks.icon}`:`${colors[props.color].txt.bricks.icon} ${colors[props.color].txt.bricks.value}`} x={colors[props.color].txt.bricks.x} y={colors[props.color].txt.bricks.y} fontSize={colors[props.color].txt.fontSize} width={colors[props.color].txt.width} align={colors[props.color].txt.align}/>
      <Text text={(colors[props.color].txt.align=="right")?`${colors[props.color].txt.mages.value} ${colors[props.color].txt.mages.icon}`:`${colors[props.color].txt.mages.icon} ${colors[props.color].txt.mages.value}`} x={colors[props.color].txt.mages.x} y={colors[props.color].txt.mages.y} fontSize={colors[props.color].txt.fontSize} width={colors[props.color].txt.width} align={colors[props.color].txt.align}/>
      <Text text={(colors[props.color].txt.align=="right")?`${colors[props.color].txt.magic.value} ${colors[props.color].txt.magic.icon}`:`${colors[props.color].txt.magic.icon} ${colors[props.color].txt.magic.value}`} x={colors[props.color].txt.magic.x} y={colors[props.color].txt.magic.y} fontSize={colors[props.color].txt.fontSize} width={colors[props.color].txt.width} align={colors[props.color].txt.align}/>
      <Text text={(colors[props.color].txt.align=="right")?`${colors[props.color].txt.troops.value} ${colors[props.color].txt.troops.icon}`:`${colors[props.color].txt.troops.icon} ${colors[props.color].txt.troops.value}`} x={colors[props.color].txt.troops.x} y={colors[props.color].txt.troops.y} fontSize={colors[props.color].txt.fontSize} width={colors[props.color].txt.width} align={colors[props.color].txt.align}/>
      <Text text={(colors[props.color].txt.align=="right")?`${colors[props.color].txt.attack.value} ${colors[props.color].txt.attack.icon}`:`${colors[props.color].txt.attack.icon} ${colors[props.color].txt.attack.value}`} x={colors[props.color].txt.attack.x} y={colors[props.color].txt.attack.y} fontSize={colors[props.color].txt.fontSize} width={colors[props.color].txt.width} align={colors[props.color].txt.align}/>
    </Group>
  );
}

export default Castle;
