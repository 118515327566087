import React, { useState, useEffect, useContext } from 'react';
import YouTube from 'react-youtube';
import YtFeed from "./ytFeed";
import {
  Button,
  IconButton,
  Icon
} from 'rsuite';
import styles from "./index.module.css";
import { CentralContext } from "../../context";
import socket from "../../socket";

const YtPlayer = props => {
  const cntx = useContext(CentralContext);
  const [currentVideo, setCurrentVideo] = useState(false);
  const [playlist, setPlaylist] = useState([]);
  const [playerState, setPlayerState] = useState(0);
  const [startTime, setStartTime] = useState(0);

  const handleState = state => {
    console.log("handle state:", state);
    setPlayerState(state.data);
  }

  const playNext = (force, fallback)=> {
    console.log("playNext, force:", force, "playerState", playerState, "playlist:", playlist, "fallback", fallback);
    if((playerState==0 || force) && playlist && playlist.length && (!currentVideo || currentVideo!=playlist[0].videoId)){
      console.log("playing next");
      setCurrentVideo(playlist.shift().videoId);
    } else if((playerState==0 || force) && playlist && playlist.length==0 && fallback){
      console.log("setting fallback", fallback.videoId, "startTime", startTime);
      setCurrentVideo(fallback.videoId);
    } else if(currentVideo == playlist[0].videoId){
      console.log("same video again");
      setCurrentVideo(playlist[1].videoId);
      playlist.shift();
      setPlaylist(playlist);
    } else console.log("no video id to play", currentVideo);
  }

  const playCurrent = ()=>{
    setCurrentVideo(playlist[0].videoId);
  }

  const handlePlayerError = e => {
    console.log(e);
  }

  useEffect(()=>{
    socket.emit("getPlaylist");
    socket.setupListener("ytPlaylist", data=>{
      console.log("videoTime:", data.videoTime, parseInt(data.videoTime));
      setStartTime(parseInt(data.videoTime));
      console.log(data.playlist);
      setPlaylist(data.playlist);
      cntx.setPlaylist((old)=>{return data.playlist});
      playNext(true, data.playlist[0]);
    })
  }, [])

  useEffect(()=>{
    if(playerState==-1){
      playCurrent();
    }
  }, [playerState])

  return (
    <div className={styles.player} style={{width: 600, display:"flex", flexDirection:"column"}}>
      <IconButton onClick={()=>props.setMedia(false)} color="red" icon={<Icon icon="close-circle"/>} placement="right" style={{alignSelf:"end", marginRight:10}}>Natrag</IconButton>
      <div style={{backgroundImage: 'url("/images/movieTape.png")', height:140, display:"flex", alignItems:"center"}}>
        {playlist.map((v, i)=>{
          if(i<5) return (
            <img src={`https://img.youtube.com/vi/${v.videoId}/default.jpg`} style={{height:85, marginLeft:3}}/>
          )
        })}
      </div>
      {currentVideo && <YouTube videoId={currentVideo} opts={{width: 590, playerVars:{autoplay:1, controls: 0, start: (startTime || 0)}}} onEnd={()=>{socket.emit("ytNext")}} onStateChange={handleState} onError={handlePlayerError}/>}
      {/*<YtFeed />*/}
      <p style={{backgroundColor: "lightgrey"}}>ovo je eksperimentalna funkcija i ne mogu garantirati da ce raditi kako treba, oprezno s dukatima!</p>
    </div>
  )
}

export default YtPlayer;
