import io from 'socket.io-client';
let socket;

export const initiateSocket = (connectionStateCallback) => {
  socket = io(`${process.env.REACT_APP_ROOT}`, {withCredentials: true});
  socket.off("connect");
  socket.on("connect", ()=>{
    connectionStateCallback(true);
  });
  socket.off("disconnect");
  socket.on("disconnect", ()=>{
    console.log("disco disco");
    connectionStateCallback(false);
  })
  console.log(`Connecting socket...`);
}
export const disconnect = () => {
  console.log('Disconnecting socket...');
  if(socket) socket.disconnect();
}
export const setupListener = (e, cb) => {
  if (!socket) return(true);
  //console.log("setting up listener for", e);
  socket.off(e);
  socket.on(e, msg => {
    if(e!="rooms")console.log(e+' event received!');
    return cb(msg);
  });
}
export const sendMessage = (room, message) => {
  if (socket) socket.emit('chat', { message, room });
}

export const emit = (e, data) => {
  socket.emit(e, data);
}

export const off = (e) => {
  socket.off(e);
}

export const getInstance = ()=>{
  console.log("socket instance", socket);
  return socket
};

export default {
  getInstance: getInstance,
  initiateSocket: initiateSocket,
  disconnect: disconnect,
  setupListener: setupListener,
  sendMessage: sendMessage,
  emit: emit,
  off: off
}
