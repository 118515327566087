import React, { useState, useEffect, useContext } from 'react';
import {
  Popover,
  Dropdown,
  Button
} from 'rsuite';
import userService from "../../services/user.service";
import { CentralContext } from "../../context";

const Options = ({ content, ...props }) => {
  const cntx = useContext(CentralContext);
  const [img, setImg] = useState();
  const [giftView, setGiftView] = useState(false);

  useEffect(()=>{
    //console.log("is premium", cntx.user.premium);
    if(cntx.user.premium){
      userService.getLargePicture(content.id)
      .then(pic=>{
        //console.log("large pic", pic);
        setImg(pic.url)
      })
      .catch(e=>console.error(e))
    } else {
      userService.getPicture(content.id)
      .then(pic=>{
        //console.log("mini pic", pic.url);
        setImg(pic.url)
      })
      .catch(e=>console.error(e))
    }
  }, [])

  return (
    <Popover {...props} full>
      <div>
        {!giftView && <div>
          {!props.invite && <div style={{display:"flex"}}>
            <img src={img}/>{!cntx.user.premium && <Button color="violet" href="https://www.patreon.com/posts/45923967" target="_blank">ukljuci velike<br/> slike</Button>}
          </div>}
          <Dropdown.Menu onSelect={(key)=>{if(key!="gift")props.onSelect(key, content)}}>
            {props.invite && <Dropdown.Item eventKey={"invite"}>Pozovi</Dropdown.Item>}
            {!props.invite && <Dropdown.Item eventKey={"pvt"}>Privatna poruka</Dropdown.Item>}
            {<Dropdown.Item eventKey={"gift"} onClick={(e)=>{e.stopPropagation();setGiftView(true)}}>Pošalji poklon</Dropdown.Item>}
            {/*<Dropdown.Item eventKey={"ignore"}>Ignoriraj</Dropdown.Item>
            <Dropdown.Item eventKey={"ban"}>Ban</Dropdown.Item>
            <Dropdown.Item eventKey={"mute"}>Mute</Dropdown.Item>
            <Dropdown.Item eventKey={"profile"}>Profil</Dropdown.Item>*/}
          </Dropdown.Menu>
        </div>}
        {giftView && <div style={{display:"flex"}}>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center"}} onClick={(e)=>props.onSelect("gift", {...content, gift:1})}>
            <p style={{fontSize:"xxx-large"}}>🍫</p>
            <div style={{display:"flex", alignItems:"center"}}>
              <img src="/images/yelo.png" style={{width:20, height:20}}/>
              <p>x10</p>
            </div>
          </div>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center", margin:"0 5px"}} onClick={(e)=>props.onSelect("gift", {...content, gift:2})}>
            <p style={{fontSize:"xxx-large"}}>🌹</p>
            <div style={{display:"flex", alignItems:"center"}}>
              <img src="/images/yelo.png" style={{width:20, height:20}}/>
              <p>x100</p>
            </div>
          </div>
          <div style={{display:"flex", flexDirection:"column", alignItems:"center"}} onClick={(e)=>props.onSelect("gift", {...content, gift:3})}>
            <p style={{fontSize:"xxx-large"}}>💎</p>
            <div style={{display:"flex", alignItems:"center"}}>
              <img src="/images/yelo.png" style={{width:20, height:20}}/>
              <p>x1000</p>
            </div>
          </div>
        </div>}
      </div>
    </Popover>
  );
};

export default Options;
