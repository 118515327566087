import React, { useState, useEffect, useContext } from 'react';
import {
  Tooltip,
  Whisper,
  Avatar,
  Icon,
  IconButton
} from 'rsuite';
import { ChatItem } from 'react-chat-elements';
import { CentralContext } from "../../context";
import socket from "../../socket";

import styles from "./privateBar.module.css";

const PrivateBar = props => {
  const cntx = useContext(CentralContext);
  const type=1;
  const [tabSelected, setTabSelected] = useState(0);
  const [pvts, setPvts] = useState([]);

  const expandChat = (chat) => {
    console.log("expand:", chat.id);
    setPvts((pvts)=>{
      return pvts.map(pvt=>{
        if(pvt.id==chat.id){
          pvt.expanded = !pvt.expanded;
        }
        return pvt;
      })
    })
  }

  const blockUser = chat => {
    socket.emit("blockUser", chat.id);
    removeChat(chat);
  }

  const removeChat = (chat) => {
    console.log("remove chat", chat.id);
    props.closeChat(chat.id)
  }

  const deleteChatHistory = (chat) => {
    socket.emit("deleteChatHistory", chat.id);
    removeChat(chat);
  }

  useEffect(()=>{
    setTabSelected(props.tab);
  }, [props.tab])

  useEffect(()=>{
    //console.log("in private chat length", cntx.chats.length);
    let filtered = cntx.chats.map(chat=>{
      //console.log("chat", chat.id);
      if(chat.id.length==24){
        //console.log("pvt", chat.id);
        return chat;
      } //else console.log("pub", chat.id);
    }).filter(c=>c);
    //console.log("filtered", filtered);
    let uniq = new Map();
    filtered.forEach((chat, i) => {
      uniq.set(chat.id, chat);
    });
    //console.log("uniq", uniq);
    setPvts([...uniq.values()]);
  }, [cntx.chats])

  return (
    (type==0)?
      <div className={styles.bar}>
        {props.chats.map((chat, i)=>{
          return <div className={styles.miniPic} key={chat.id}>
            <Whisper
            trigger="hover"
            placement="top"
            onClick={()=>props.setCurrentChat(chat.id)}
            speaker={
              <Tooltip>
                {chat.channel.name}
              </Tooltip>
            }>
            {(chat.id=="0" || chat.id==0 || i==0)?<Icon icon={chat.channel.picture} size="3x"/>
            :
              <div className={styles.avatarAndX}>
                <Avatar src={chat.channel.picture}/>
                <p className={styles.eks} onClick={()=>props.closeChat(chat.id)}>x</p>
              </div>
            }
            </Whisper>
          </div>
        })}
      </div>
    :
      <div>
        <div>
          {!props.mobile && <div className={styles.tabset}>
            <input type="radio" name="tabset" id="tab1" aria-controls="javno" onChange={(e)=>setTabSelected(0)} checked={tabSelected==0}/>
            <label htmlFor="tab1" onClick={()=>{props.setCurrentChat(props.chats[0].id);props.showChat()}}>Javno</label>

            <input type="radio" name="tabset" id="tab2" aria-controls="privatno" onChange={(e)=>setTabSelected(1)} checked={tabSelected==1}/>
            <label htmlFor="tab2" onClick={()=>{props.hideChat();props.setNewChatAlert(false)}}>Privatno {(props.newChatAlert)?<span style={{color: "red"}}>({props.newChatAlert})</span>:null}</label>
          </div>}
          {!props.chatVisible && <div style={{maxHeight: (props.mobile)?"calc(100vh - 50px)":443, overflow: "auto"}}>
            {pvts.length>0 && pvts.sort((a, b)=>{
              if(a.msgs.length>0 && b.msgs.length>0 && a.msgs[a.msgs.length-1].date>b.msgs[b.msgs.length-1].date) return -1;
              else return 1;
            }).map((chat, i)=>{
              return (!chat.expanded) ?
                <div style={{display: "flex"}}>
                  <div style={{width: (props.mobile?"calc(100vw - 36px)":"null")}}>
                    <ChatItem
                      avatar={chat.channel.picture}
                      alt={chat.channel.name}
                      title={chat.channel.name}
                      subtitle={!chat.msgs.length>0?"":(chat.msgs[chat.msgs.length-1].text.length>15)?chat.msgs[chat.msgs.length-1].text.substr(0,15)+"...":chat.msgs[chat.msgs.length-1].text}
                      date={!chat.msgs.length>0?new Date():chat.msgs[chat.msgs.length-1].date}
                      unread={!chat.unread?0:chat.unread}
                      statusColor={Math.random()>0.5?"green":"red"}
                      statusColorType={"encircle"/*Math.random()>0.5?"encircle":"badge"*/}
                      onContextMenu={(a)=>{console.log(a)}}
                      onClick={()=>{props.setCurrentChat(chat.id);props.showChat();props.resetUnreadForChat(chat)}} />
                  </div>
                  <div onClick={()=>expandChat(chat)} style={{backgroundColor:"dodgerblue", display:"flex", alignItems:"center", justifyContent:"center", width:36, borderBottom: "1px solid black", borderRadius: "0 15px 15px 0"}}>
                    <Icon icon="angle-left" appearance="primary" size="x3"/>
                  </div>
                </div>
              :
                <div style={{display: "flex", justifyContent:"space-between", alignItems: "center", height: 72, backgroundColor:"white"}}>
                  <Avatar src={chat.channel.picture} size="lg"/>
                  <div>
                    <IconButton style={{margin: "0 5px 0 5px"}} icon={<Icon icon="ban"/>} appearance="primary" color="red" onClick={()=>blockUser(chat)}/>
                    <IconButton style={{margin: "0 5px 0 5px"}} icon={<Icon icon="trash"/>} appearance="default" color="green" onClick={()=>deleteChatHistory(chat)}/>
                    <IconButton style={{margin: "0 5px 0 5px"}} icon={<Icon icon="close"/>} appearance="primary" color="violet" onClick={()=>removeChat(chat)}/>
                  </div>
                  <div onClick={()=>expandChat(chat)} style={{backgroundColor: "dodgerblue", display:"flex", alignItems:"center", justifyContent:"center", width:36, height: 72, borderBottom: "1px solid black", borderRadius: "0 15px 15px 0"}}>
                    <Icon icon="angle-right" appearance="primary" size="x3"/>
                  </div>
                </div>
            })
          }
          {pvts.map((chat, i)=>{
            //return <p>{chat.id}</p>;
          })}
            {props.chats.length<=1 && <h3>nemate privatnih poruka</h3>}
          </div>}
        </div>
      </div>
  )
}

export default PrivateBar;
