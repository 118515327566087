import React from 'react';
import {
  Button,
  ButtonToolbar
} from 'rsuite';
import {CentralContext} from "../../context";

const ColorPicker = props => {
  return (
    <CentralContext.Consumer>
    {(context)=>(
      <ButtonToolbar>
      {
        [["red", "Crveni"], ["blue", "Plavi"], ["green", "Zeleni"], ["yellow", "Zuti"]].map(color=>{
          //console.log(props.update.players[color[0]].picture);
          return (props.update.players[color[0]].picture.length && !props.update.players[color[0]].unset)?
            <img src={props.update.players[color[0]].picture}/>
          :
            <Button color={color[0]} appearance={(context.color==color[0])?"ghost":"default"} onClick={()=>props.colorPick(color[0])}>{color[1]}</Button>
        })
      }
      </ButtonToolbar>
    )}
    </CentralContext.Consumer>
  );
}

export default ColorPicker;
