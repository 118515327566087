import React, { useState, useEffect, useRef } from 'react';
import { Group, Circle, Text } from 'react-konva';

const BoardCW = props => {

  return (
    <Group>
      {/*<Circle x={200} y={200} fill={"#FF0000"} radius={150} />
      <Text text={"name"} x={100} y={100}/>*/}
    </Group>
  )
}

export default BoardCW;
