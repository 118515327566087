import React, { useState, useEffect } from 'react';
import { Stage, Layer, Rect, Circle, Image } from 'react-konva';
import useImage from "use-image";
import { screenSize } from "../../hooks";
import Board from "./board";
import Castle from "./castle";
import Card from "./card";

const Game = (props) => {
  const { height, width, mobile } = screenSize();
  const [update, setUpdate] = useState({
    players: {},
    hand: [false,false,false,false,false,false,false]
  });
  const [red, setRed] = useState({});
  const [blue, setBlue] = useState({});
  const [yellow, setYellow] = useState({});
  const [green, setGreen] = useState({});
  const [hand, setHand] = useState([]);
  const [throwable, setThrowable] = useState([]);
  const [fresh, setFresh] = useState([]);
  const [lastOnDeck, setLastOnDeck] = useState({});
  const [imgX] = useImage('/assets/x.png');
  const [animations, setAnimations] = useState([]);
  const [triggerAnim, setTriggerAnim] = useState(false);
/*
  const nextAnim = () => {
    console.log("animation finished, start next one", animations.length);
    if(animations.length>0){
      let anim = animations.shift();
      console.log("anim", anim);
      if(anim && anim.target=="hand"){
        hand[anim.index].x = anim.x;
        hand[anim.index].y = anim.y;
        hand[anim.index].reset = anim.reset;
        hand[anim.index].delay = anim.delay;
        hand[anim.index].immediate = anim.immediate;
        setHand([...hand]);
      }
      if(animations.length)setAnimations([...animations]);
      else setAnimations([]);
    }
  }*/
  const switchLastOnDeck = () => {
    console.log("throwable.length", throwable.length);
    if(throwable.length){
      let thr = throwable.pop();
      setLastOnDeck({...thr, x: thr.x, y: thr.y});
      setThrowable([...throwable])
    }
  }
/*
  useEffect(()=>{
    if(triggerAnim && animations.length){
      nextAnim();
    } else if(triggerAnim) setTriggerAnim(false);
  }, [triggerAnim])

  useEffect(()=>{
    console.log("animations changed", animations);
    setTriggerAnim(true);
    //if(animations.length)nextAnim();
  }, [animations.length])*/

  useEffect(()=>{
    if(triggerAnim)setTriggerAnim(false);
    if(props?.update?.players?.red)setRed(props.update.players.red);
    if(props?.update?.players?.blue)setBlue({...props.update.players.blue});
    if(props?.update?.players?.yellow)setYellow(props.update.players.yellow);
    if(props?.update?.players?.green)setGreen(props.update.players.green);
    let frsh = [];
    let anims = [];
    if(props?.update?.hand)setHand((oldCards)=>{
      let threw = [];
      oldCards.forEach((c,i)=>{
        if(props.update.hand.length>0 && props.update.hand.length==oldCards.length && props.update.hand[i].name!=oldCards[i].name){
          props.update.hand[i].from = {
            x: 150,
            y: 50
          };
          props.update.hand[i].x = i*75;
          props.update.hand[i].y = 200;
          props.update.hand[i].reset = true;
          props.update.hand[i].onRest = ()=>{
            console.log("this", props.update.hand[i]);
          }
          console.log("deck of new cards with old and new");
          threw.push({
            old: oldCards[i],
            new: props.update.hand[i],
            index: i
          });
          props.update.hand[i].from = {
            x: 150,
            y: 50
          }
          props.update.hand[i].index = i;
          let anim = {
            target: "hand",
            x: i*75,
            y: 200,
            reset: true,
            index: i,
            delay: 500
          };
          console.log("find index", anims.findIndex(a => a.index === i));
          if(anims.findIndex(a => a.index === i)==-1)anims.push(anim);
          //frsh.push(props.update.hand[i]);
        } else if(props.update.hand.length>0 && oldCards.length==0) {
          console.log("initial deck");
          props.update.hand[i].onRest = ()=>console.log("initial finished", i);
          props.update.hand[i].from = {
            x: 150,
            y: 50
          }
          props.update.hand[i].x = i*75;
          props.update.hand[i].y = 200;
          props.update.hand[i].index = i;
          props.update.hand[i].reset = true;
          props.update.hand[i].delay = 100*i;
          props.update.hand[i].immediate = false;
          ///
          let anim = {
            target: "hand",
            x: i*75,
            y: 200,
            index: i,
            reset: true,
            delay: 500,
            immediate: true
          }
          props.update.hand[i].index = i;
          console.log("find index", anims.findIndex(a => a.index === i));
          if(anims.findIndex(a => a.index === i)==-1)anims.push(anim);
          //props.update.hand[i].immediate=true;
        } else if(props.update.hand.length>0 && props.update.hand.length==oldCards.length && props.update.hand[i].name==oldCards[i].name){
          console.log("new and old same");
          if(!props.update.hand[i].x || props.update.hand[i].x!=i*75){
            props.update.hand[i].x = i*75;
            props.update.hand[i].y = 200;
            props.update.hand[i].immediate=false;
          }
          props.update.hand[i] = oldCards[i];
          props.update.hand[i].immediate = true;
        }
      })
      console.log("anims to set", anims);
      let uniq = anims.filter((obj, key, array) => array.map((obj2) => obj.index !== obj2.index));
      //if(uniq.length)setAnimations([...uniq]);
      console.log("thow cards", threw);
      let toThrew = [];
      threw.forEach(card => {
        if(card.old){
          card.old.from = {
            x: card.index*75,
            y: 200
          }
          card.old.x = 250;
          card.old.y = 50;
          card.old.reset = true;
          card.old.immediate = false;
          toThrew.push(card.old);
        }
      });
      setThrowable(toThrew);
      //setFresh(frsh);
      return props.update.hand;
    });
  }, [props.update]);

  useEffect(()=>{
    //if(throwable.length>0)
  }, [throwable])
/*
  useEffect(()=>{
    let changed = false;
    fresh.forEach((card, i) => {
      if(hand[card.index].y!=200){
        changed = true;
        hand[card.index].to.x = card.index*75;
        hand[card.index].to.y = 200;
        hand[card.index].x = card.index*75;
        hand[card.index].y = 200;
        hand[card.index].from.x = 150;
        hand[card.index].from.y = 50;
        console.log("new cords", hand[card.index]);
      }
    });
    if(changed)setHand(hand);
  }, [fresh])*/

  return (
    // Stage - is a div wrapper
    // Layer - is an actual 2d canvas element, so you can have several layers inside the stage
    // Rect and Circle are not DOM elements. They are 2d shapes on canvas
    /*

    */
    <Stage width={(!props.mobile)?600:width} height={!props.mobile?600:height-90} x={props.mobile?width/2-300:0} y={props.mobile?height/2-300:0} draggable={props.mobile} dragBoundFunc={(!props.mobile)?null:(pos)=>{
          return {
            x: ((width<600 && pos.x>width-600)?(pos.x>0)?0:pos.x:width-630) || 0,
            y: ((height<600 && pos.y>height-600)?(pos.y>0)?0:pos.y:height-630) || 0,
          };
        }}>
      <Layer x={(width<600)?120:50} y={50}>
        <Board update={update} socket={props.socket}/>
        <Castle color="red" update={red}/>
        <Castle color="blue"  update={blue}/>
        <Castle color="green"  update={green}/>
        <Castle color="yellow"  update={yellow}/>
        {lastOnDeck && <Card x={lastOnDeck.x} y={lastOnDeck.y} data={lastOnDeck} />}
        {throwable && throwable.map(t=>{
          return <Card x={t.x} y={t.y} from={t.from} reset={t.reset} immediate={t.immediate} delay={t.delay} onRest={switchLastOnDeck} data={t} />
        })}
        {(hand.length)?hand.map((c, k)=><Card x={c.x} y={c.y} from={c.from} reset={c.reset} immediate={c.immediate} delay={c.delay} onRest={c.onRest} key={k} data={c} onClick={()=>{console.log("card click");props.socket.emit("playCard", k);}}/>):null}
      </Layer>
      <Layer>
        <Image image={imgX} x={550} y={0} scaleX={0.75} scaleY={0.75} onClick={()=>props.socket.emit("joinRoom", 0)} onTap={()=>props.socket.emit("joinRoom", 0)} />
      </Layer>
    </Stage>
  );
}
export default Game;
