import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Container,
  Drawer,
  Divider,
  Header,
  Content,
  Footer,
  Sidebar,
  Sidenav,
  Nav,
  Icon,
  IconButton,
  Dropdown,
  Navbar,
  Notification,
  Modal,
  Input,
} from 'rsuite';
import { useParams, useHistory } from 'react-router-dom';
import { useQuery, screenSize } from '../hooks';
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from 'react-device-detect';
import FacebookLogin from 'react-facebook-login';
import userService from '../services/user.service';
import rewardsService from '../services/rewards.service';
import Chat from '../components/Chat';
import PlayerList from '../components/PlayerList';
import RoomList from '../components/RoomList';
import Settings from '../components/settings';
import socket from '../socket';
import Game from '../components/GamePicker';
import NewRoom from '../components/NewRoom';
import YtPlayer from '../components/YtPlayer';
import { CentralContext } from '../context';
import soundFX from '../soundfx';
import Help from './help';
import styles from './index.module.css';
import Inventory from '../components/Inventory';

const roomPopups = {
  NONE: 0,
  OWNER_WAITING: 1,
  GUEST_WAITING: 2,
};

const defaultRoomUpdate = {
  gameType: 0,
  muerte: false,
  muerteOut: 0,
  turn: 1,
  dice: 1,
  players: {
    red: {
      figures: {
        0: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        1: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        2: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        3: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
      },
      name: '',
      picture: '',
      id: 0,
    },
    blue: {
      figures: {
        0: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        1: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        2: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        3: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
      },
      name: '',
      picture: '',
      id: 0,
    },
    yellow: {
      figures: {
        0: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        1: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        2: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        3: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
      },
      name: '',
      picture: '',
      id: 0,
    },
    green: {
      figures: {
        0: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        1: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        2: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
        3: {
          onField: 0,
          alive: 1,
          type: 0,
          movable: false,
        },
      },
      name: '',
      picture: '',
      id: 0,
    },
  },
};

const Pages = (props) => {
  const generateDefaultValues = () => {
    let v = {
      gameType: 0,
      muerte: false,
      muerteOut: 0,
      turn: 1,
      dice: 1,
    };
    v.players = {};
    ['red', 'blue', 'yellow', 'green'].map((color) => {
      //console.log("setting color in object", color);
      v.players[color] = {
        unset: true,
        name: '',
        picture: '',
        id: 0,
      };
      v.players[color].figures = {};
      [0, 1, 2, 3].map((fig) => {
        v.players[color].figures[fig] = {
          onField: 0,
          alive: 1,
          type: 7,
          movable: false,
        };
      });
    });
    return v;
  };
  const generateDefaultValues2 = () => {
    return JSON.parse(
      JSON.stringify({
        gameType: 0,
        muerte: false,
        muerteOut: 0,
        turn: 1,
        dice: 1,
        players: {
          red: { unset: true },
          blue: { unset: true },
          yellow: { unset: true },
          green: { unset: true },
        },
        positions: [
          { color: 'red', figure: 0, position: 0 },
          { color: 'red', figure: 1, position: 0 },
          { color: 'red', figure: 2, position: 0 },
          { color: 'red', figure: 3, position: 0 },
          { color: 'blue', figure: 0, position: 0 },
          { color: 'blue', figure: 1, position: 0 },
          { color: 'blue', figure: 2, position: 0 },
          { color: 'blue', figure: 3, position: 0 },
          { color: 'yellow', figure: 0, position: 0 },
          { color: 'yellow', figure: 1, position: 0 },
          { color: 'yellow', figure: 2, position: 0 },
          { color: 'yellow', figure: 3, position: 0 },
          { color: 'green', figure: 0, position: 0 },
          { color: 'green', figure: 1, position: 0 },
          { color: 'green', figure: 2, position: 0 },
          { color: 'green', figure: 3, position: 0 },
        ],
      })
    );
  };
  const { height, width, mobile } = screenSize();
  const scope = 'public_profile,email';
  let query = useQuery();
  let history = useHistory();
  const cntx = useContext(CentralContext);
  const [expanded, setExpanded] = useState(true);
  const [authType, setAuthType] = useState(-1);
  const [userId, setUserId] = useState(false);
  const [response, setResponse] = useState('');
  const [connected, setConnected] = useState(false);
  const [inGame, setInGame] = useState(false);
  const [showRoomOptions, setShowRoomOptions] = useState(false);
  const [showRoomPopup, setShowRoomPopup] = useState(0);
  const [update, setUpdate] = useState({ ...defaultRoomUpdate });
  const [drawerOption, setDrawerOption] = useState('chat');
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedOption, setSelectedOption] = useState(false);
  const [rewards, setRewards] = useState(false);
  const [currentRoom, setCurrentRoom] = useState(0);
  const [lastAd, setLastAd] = useState(0);
  const [announcement, setAnnouncement] = useState(false);
  const [openedInvites, setOpenedInvites] = useState(false);
  const [invitesOn, setInvitesOn] = useState(true);
  const [invites, setInvites] = useState([]);
  const [gameErrors, setGameErrors] = useState([]);
  const [currentMobileView, setCurrentMobileView] = useState(2);
  const [chatTab, setChatTab] = useState(0);
  const [modal, setModal] = useState({ opened: false });
  const [media, setMedia] = useState({
    show: false,
  });
  const [rewardCode, setRewardCode] = useState('');

  const onLogin = (resp) => {
    console.log('onLogin', resp);
    userService
      .sendFbAuth({ ...resp, visitorId: cntx.visitorId })
      .then((d) => {
        if (d.auth) {
          console.log('setting cntx user', resp, resp.data, cntx.setUser);
          cntx.setUser(resp.authResponse || resp);
          initSocket();
        } else {
          cntx.setErrors([
            {
              error: 'fbLogin',
              msg: 'Morate se logirati preko facebook racuna da bi igrali igru',
            },
          ]);
          console.log('not authed');
        }
      })
      .catch((e) => {
        console.error(e);
        cntx.setErrors([{ error: 'fbLogin', msg: 'might be CORS issue' }]);
      });
  };

  const onAlreadyLoggedin = () => {
    userService.getMe().then((d) => {
      if (d.auth) {
        console.log('it got me', d);
        cntx.setUser(d.data);
        initSocket();
      } else {
        console.log("it just doesn't get me");
      }
    });
  };

  const initSocket = () => {
    console.log('initing socket');
    socket.initiateSocket((c) => {
      setConnected(c);
    });
    socket.setupListener('youAre', (data) => {
      cntx.setUser(data);
    });
    socket.setupListener('msg', (data) => {
      if (!data.auth && data.cmd == 'reauth') {
        console.log('REAUTH');
        socket.emit('fbAuth', cntx.user);
      } else console.log(data);
      setResponse(data);
    });
    socket.setupListener('newRoom', (data) => {
      console.log('switch room');
    });
    socket.setupListener('banned', (data) => {
      console.log('!!!banned!!!');
      history.push('/banned', data);
    });
    socket.setupListener('retry', (data) => {
      console.log('request failed, retrying', data);
      socket.emit(data);
    });
    socket.setupListener('startGame', (data) => {
      console.log('startingGame', data);
      setInGame(true);
      setUpdate((oldValues) => mutateUpdate(data, oldValues));
    });
    socket.setupListener('nextTurn', (data) => {
      console.log('nextTurn', data);
      setUpdate((oldValues) => mutateUpdate(data, oldValues));
    });
    socket.setupListener('hand', (data) => {
      console.log('hand', data);
      setInGame(true);
      setUpdate((oldValues) =>
        mutateUpdate({ hand: data, type: 3 }, oldValues)
      );
    });
    socket.setupListener('setTurn', (data) => {
      //console.log("setTurn", data);
      setUpdate((oldValues) => mutateUpdate({ turn: data }, oldValues));
      //setInGame(true);
    });
    socket.setupListener('joinedRoom', (data) => {
      console.log('joinedRoom', data);
      joinedRoom(data);
      //setInGame(true);
    });
    socket.setupListener('userJoin', (data) => {
      //console.log("userJoin", data);
      //joinedRoom(data);
      //setInGame(true);
    });
    socket.setupListener('update', (data) => {
      //console.log("update ", data);
      if (data) setUpdate((oldValues) => mutateUpdate(data, oldValues));
    });
    socket.setupListener('gameError', (data) => {
      console.log('gameError ', data?.msg);
      setGameErrors([data]);
    });
    socket.setupListener('info', (data) => {
      console.log('info ', data);
      if (data.type == 'gift') {
        let giftEmoji = ['-', '🍫', '🌹', '💎'][data.giftId];
        open(
          'info',
          <div>
            Korisnik <img src={data.from.picture} /> vam je poslao{' '}
            <span style={{ fontSize: 'xxx-large' }}>{giftEmoji}</span>
          </div>
        );
      } else if (data.type == 'giftSent') {
        open('info', `Poslali ste poklon!`);
      } else if (data.type == 'giftSelf') {
        open('error', `Saljete poklon samom sebi!`);
      } else if (data.type == 'info') {
        open('info', data.msg);
      } else if (data.type == 'error') {
        open('error', data.msg);
      } else console.log('unhandled info type', data.type);
    });
    socket.setupListener('setColor', (data) => {
      //console.log("setColor ", data);
      cntx.setColor(data);
    });
    socket.setupListener('dice', (data) => {
      //console.log("dice ", data);
      setUpdate((oldValues) => mutateUpdate(data, oldValues));
    });
    socket.setupListener('moveFigure', (data) => {
      //console.log("moveFigure ", data);
      setUpdate((oldValues) => mutateUpdate(data, oldValues));
    });
    socket.setupListener('playerWon', (data) => {
      console.log('playerWon ', data);
      //setUpdate(data);
    });
    socket.setupListener('elMuerte', (data) => {
      console.log('elMuerte', data);
      soundFX.playGameSound('elmuerte');
      setUpdate((oldValues) => mutateUpdate(data, oldValues));
      //setUpdate(data);
    });
    socket.setupListener('muerteOut', (data) => {
      console.log('muerteOut', data);
      setUpdate((oldValues) => mutateUpdate({ muerteOut: data }, oldValues));
      //setUpdate(data);
    });
    socket.setupListener('announcement', (data) => {
      setAnnouncement(data.msg);
    });
    socket.setupListener('invite', (data) => {
      //console.log("received invite", data, invitesOn);
      setInvites((oldInvites) => {
        const uniq = new Map();
        oldInvites.forEach((i) => {
          uniq.set(i.by.id, i);
        });
        uniq.set(data.by.id, data);
        return [...uniq.values()];
      });
    });
  };

  const removeInvite = (data) => {
    setInvites((oldInvites) => {
      const uniq = new Map();
      oldInvites.forEach((i) => {
        if (i.by.id != data.by.id) uniq.set(i.by.id, i);
      });
      return [...uniq.values()];
    });
  };

  const mutateUpdate = (toMutate, oldValues) => {
    //console.log("to mutate", toMutate);
    let myTurn = -1;
    let mutated = { ...oldValues };

    if (toMutate.id) {
      mutated.id = toMutate.id;
    }
    if (toMutate.owner) {
      mutated.id = toMutate.owner;
    }
    if (toMutate.type) {
      mutated.gameType = toMutate.type;
    }
    if (toMutate.type == 3) {
      if (toMutate.players) {
        for (const color in toMutate.players) {
          if (toMutate.players[color].unset) {
            //console.log("unsetting player", color, "from", mutated.players[color], "to", {...mutated.players[color], ...defaultRoomUpdate.players[color]}, "part", {...defaultRoomUpdate.players[color]});
            mutated.players[color] = {
              ...mutated.players[color],
              ...defaultRoomUpdate.players[color],
            };
            mutated.players[color].unset = true;
          } else if (toMutate.players[color]?.player?.name) {
            mutated.players[color].unset = false;
            mutated.players[color] = {
              ...mutated.players[color],
              ...toMutate.players[color].player,
            };
          }
          if (toMutate.players[color].alive) {
            for (let a = 0; a < toMutate.players[color].alive.length; a++) {
              mutated.players[color].figures[a].alive =
                toMutate.players[color].alive[a];
            }
          }
          if (toMutate.players[color].castle) {
            mutated.players[color].castle = toMutate.players[color].castle;
          }
        }
      }
      if (toMutate.hand) {
        mutated.hand = toMutate.hand;
      }
      return mutated;
    }
    if (toMutate.muerte || toMutate.muerte === false) {
      mutated.muerte = toMutate.muerte;
    }

    if (toMutate.muerteOut || toMutate.muerteOut == 0) {
      mutated.muerteOut = toMutate.muerteOut;
    }
    if (toMutate.players) {
      for (const color in toMutate.players) {
        if (toMutate.players[color].unset) {
          //console.log("unsetting player", color, "from", mutated.players[color], "to", {...mutated.players[color], ...defaultRoomUpdate.players[color]}, "part", {...defaultRoomUpdate.players[color]});
          mutated.players[color] = {
            ...mutated.players[color],
            ...defaultRoomUpdate.players[color],
          };
          mutated.players[color].unset = true;
        } else if (toMutate.players[color]?.player?.name) {
          mutated.players[color].unset = false;
          mutated.players[color] = {
            ...mutated.players[color],
            ...toMutate.players[color].player,
          };
        }
        if (toMutate.players[color].alive) {
          for (let a = 0; a < toMutate.players[color].alive.length; a++) {
            mutated.players[color].figures[a].alive =
              toMutate.players[color].alive[a];
          }
        }
        if (toMutate.players[color].types) {
          for (let t = 0; t < toMutate.players[color].types.length; t++) {
            mutated.players[color].figures[t].type =
              toMutate.players[color].types[t];
          }
        }
      }
    }
    for (const color in mutated.players) {
      for (const fig in mutated.players[color].figures) {
        mutated.players[color].figures[fig].movable = false;
      }
      if (
        toMutate[color] &&
        toMutate[color]?.figure?.movable &&
        toMutate[color].figure.movable.length
      ) {
        for (const fig in toMutate[color].figure.movable) {
          //console.log("set movable", fig, "to", toMutate[color].figure.movable[fig]);
          mutated.players[color].figures[
            toMutate[color].figure.movable[fig]
          ].movable = true;
        }
      }
      if (
        toMutate[color] &&
        toMutate[color].figure &&
        (toMutate[color].onField || toMutate[color].onField == 0)
      ) {
        mutated.players[color].figures[
          parseInt(toMutate[color].figure)
        ].onField = toMutate[color].onField;
      }
      if (toMutate[color]?.alive) {
        for (let a = 0; a < toMutate[color].alive.length; a++) {
          mutated.players[color].figures[a].alive = toMutate[color].alive[a];
        }
      }
    }
    //console.log("setting positions", toMutate.positions);
    if (toMutate.positions) {
      for (let p = 0; p < toMutate.positions.length; p++) {
        let figure = toMutate.positions[p];
        //console.log("figure", figure);
        mutated.players[figure.color].figures[figure.figure].onField =
          figure.position;
      }
    }
    if (toMutate.turn || toMutate.turn == 0) {
      mutated.turn = toMutate.turn;
      //console.log("try to play turn sound", mutated.turn, ["red", "blue", "yellow", "green"][mutated.turn]);
      if (
        mutated.players[['red', 'blue', 'yellow', 'green'][mutated.turn]].id ==
        cntx.user.id
      ) {
        //console.log("turn sound");
        soundFX.playGameSound('turn');
      }
    } else delete mutated.turn;
    if (toMutate.dice) {
      mutated.dice = toMutate.dice;
    } else delete mutated.dice;

    return mutated;
  };

  const getRewards = () => {
    rewardsService
      .getRewards()
      .then((rwrds) => {
        //console.log("rewards:", rwrds);
        if (rwrds && rwrds.length) setRewards(rwrds);
        else setRewards(false);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const newRoom = (e) => {
    //console.log("new room", e);
    socket.emit('newRoom');
  };
  const createRoom = (options) => {
    //console.log("new room", options);
    socket.emit('newRoom', options);
  };

  const joinedRoom = (roomState) => {
    //console.log("roomState", roomState);
    if (roomState.id == 0) {
      //console.log("set default update", generateDefaultValues2());
      setCurrentRoom(0);
      cntx.setCurrentChat(0);
      //setUpdate(generateDefaultValues());
      setUpdate((oldValues) =>
        mutateUpdate(generateDefaultValues2(), oldValues)
      );
      setShowRoomOptions(false);
    } else {
      setUpdate((oldValues) => mutateUpdate(roomState, oldValues));
      setCurrentRoom(roomState.id);
      cntx.setCurrentChat(0);
    }
    if (!roomState.started) {
      setInGame(false);
      setShowRoomOptions(true);
      //console.log("cntx", cntx);
      if (roomState.owner == cntx?.user?.id) {
        //console.log("owner waiting, show start button");
        setShowRoomPopup(roomPopups.OWNER_WAITING);
      } else {
        //console.log("guest waiting for game to start", roomState.owner, "!=", cntx.user.id);
        if (roomState.type != 0) {
          //type 0 = chat room
          setShowRoomPopup(roomPopups.GUEST_WAITING);
        } else {
          setShowRoomOptions(false);
        }
      }
    } else {
      setShowRoomOptions(false);
      setShowRoomPopup(roomPopups.NONE);
      setInGame(true);
      //console.log("set figures here i guess");
      //console.log("color pick:", roomState.players);
    }
    /*
    setGameplay({
      reset: true,
      roomType: roomState.type,
      positions: roomState.positions,
      muerte: roomState.muerte
    })*/
  };

  let injectedDimensions = {
    '--screenHeight': `${height}px`,
    '--screenWidth': `${width}px`,
  };

  const setMobileView = (view) => {
    setCurrentMobileView(view);
    if (cntx.mobileView != view) {
      cntx.setMobileView(view);
    }
  };

  const exitToLobby = () => {
    socket.emit('joinRoom', 0);
  };

  const open = (funcName, data) => {
    Notification[funcName]({
      title: funcName,
      description: <p>{data}</p>,
    });
  };

  if (!cntx.user.adFree) {
    if (
      (window.gdsdk ||
        (typeof window.sdk !== 'undefined' &&
          window.sdk.showBanner !== 'undefined')) &&
      lastAd < Date.now() - 600000 * 2
    ) {
      //console.log("show ad!");
      if (window.gdsdk) window.gdsdk.showAd();
      else if (
        typeof window.sdk !== 'undefined' &&
        window.sdk.showBanner !== 'undefined'
      )
        window.sdk.showBanner();
      setLastAd(Date.now());
    } //else console.log("ads not inited");
  } else {
    //console.log("ad free user");
  }

  const getTimeTillDate = (date) => {
    // Get today's date and time
    var now = new Date().getTime();

    // Find the distance between now and the count down date
    var distance = new Date(date).getTime() - now;

    // Time calculations for days, hours, minutes and seconds
    var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    var hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  };

  const showInternalAd = (adName) => {
    switch (adName) {
      case 'premiumPlayerList':
        setModal({
          opened: true,
          title: 'Premium',
          description: (
            <div>
              <p>
                Za prikaz slika u vecoj kvaliteti, potreban vam je premium.
                Premium je trenutno dostupan samo Patreonima.
              </p>
            </div>
          ),
          yes: 'Patreon',
          yesCb: () => {
            let win = window.open(
              'https://www.patreon.com/iggyZiggy',
              '_blank'
            );
            win.focus();
          },
          no: 'Ne, hvala',
        });
        break;
      default:
    }
  };

  const roomFilterNames = ['Sve', 'Otvorene', 'Pokrenute'];
  const setRoomList = () => {
    cntx.setRoomListFilter((oldValue) => {
      oldValue++;
      if (oldValue > 2) oldValue = 0;
      return oldValue;
    });
  };

  useEffect(() => {
    if (window.FB) {
      console.log('in fb canvas', query.get('id'));
      if (query.get('id') && query.get('id') != 'undefined') {
        console.log('user authed already, nothing to do');
        setUserId(query.get('id'));
        onAlreadyLoggedin();
      } else if (!connected) {
        console.log('use window.FB to auth user');
        window.FB.getLoginStatus(function (response) {
          // Check login status on load, and if the user is
          // already logged in, go directly to the welcome message.
          if (response.status == 'connected') {
            onLogin(response.authResponse);
          } else {
            // Otherwise, show Login dialog first.
            window.FB.login(
              function (response) {
                onLogin(response);
              },
              { scope: scope }
            );
          }
        });
      }
    } else {
      console.log('not in canvas, show login options');
      setAuthType(1);
    }
    if (width < 854) setShowDrawer(true);
    if (width >= 854) setShowDrawer(false);
  }, []);

  useEffect(() => {
    if (connected) {
      socket.emit('whoAmI');
    }
    if (!connected) {
      setInGame(false);
      setShowRoomOptions(false);
    }
    getRewards();
  }, [connected]);

  useEffect(() => {
    if (invites.length && invitesOn && !openedInvites) {
      setOpenedInvites(invitesOn);
    }
  }, [invites]);

  useEffect(() => {
    console.log(
      'got game errors',
      gameErrors,
      cntx.settings.misc.gameErrorPopups
    );
    if (gameErrors.length) {
      let data = gameErrors.pop();
      if (cntx.settings.misc.gameErrorPopups)
        open(data.type || 'error', data?.msg ? data.msg : 'Nepoznata greska!');
      setGameErrors([]);
    }
  }, [gameErrors]);

  useEffect(() => {
    //console.log("view changed in cntx");
    setSelectedOption({ key: 'removed', data: 'no data' });
    setMobileView(cntx.mobileView);
    cntx.setCurrentChat(0);
  }, [cntx.mobileView]);

  return (
    <CentralContext.Consumer>
      {(context) => (
        <div style={injectedDimensions}>
          <BrowserView>
            {!connected && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <img
                  src="/images/covercroped.png"
                  style={{ borderRadius: '0 0 10px 10px' }}
                />
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <img src="/assets/Lik_jason_crveni.png" />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <p
                      style={{
                        maxWidth: 400,
                        textAlign: 'center',
                        background: 'lightblue',
                        border: '1px solid',
                        borderRadius: '0 0 10px 10px',
                        padding: 10,
                        marginBottom: 3,
                      }}
                    >
                      Za igranje je potreban facebook račun, kliknite na gumb za
                      ulaz. Potrebne su nam minimalne informacije najviše iz
                      razloga da se ne morate registrirati i ručno upisivati
                      podatke.
                    </p>
                    <div>
                      <FacebookLogin
                        appId={process.env.REACT_APP_FB_APP_ID}
                        autoLoad={true}
                        fields="name,email,picture"
                        scope={scope}
                        callback={onLogin}
                        version="v14.0"
                        onFailure={(f) => console.log(f)}
                        disableMobileRedirect={true}
                      />
                    </div>
                    {/*(context.errors && context.errors.length>0) && <Help />*/}
                    {window.location.hostname != 'cnljs.com' &&
                      window.location.hostname != 'localhost' && (
                        <p>
                          igrajte na{' '}
                          <a href="https://cnljs.com">
                            glavnoj stranici (cnljs.com)
                          </a>{' '}
                          ako vam se ovdje igra ne ucitava
                        </p>
                      )}
                  </div>
                  <img src="/assets/Lik_sheriff_plavi.png" />
                </div>
                <Divider />
                <p>Podrži razvoj igre koristeći jednu od usluga</p>
                <div style={{}}>
                  <img src="/images/patreon.png" style={{ maxWidth: 200 }} />
                  <img
                    src="/images/logo_paysafecard.png"
                    style={{ maxWidth: 400 }}
                  />
                  <img src="/images/xbon.png" style={{ maxWidth: 200 }} />
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <p>Developed by:</p>
                  <img src="images/proclive.png" style={{ maxWidth: 200 }} />
                  <a href="/TAC">Terms and Conditions</a> and{' '}
                  <a href="http://proclive.io/tosnpp/" target="_blank">
                    Privacy Policy
                  </a>
                </div>
              </div>
            )}
            {connected && (
              <div className={styles.main}>
                <div className={styles.wrapper}>
                  <div className={styles.game}>
                    <Game update={update} socket={socket} game="cnljs" />
                  </div>
                  {!inGame && (
                    <div>
                      {!media.show && (
                        <div className={styles.options}>
                          {showRoomOptions && (
                            <NewRoom
                              cancelRoomOptions={() =>
                                setShowRoomOptions(false)
                              }
                              createRoom={createRoom}
                              popup={showRoomPopup}
                              exitRoom={exitToLobby}
                              update={update}
                            />
                          )}
                          {!showRoomOptions && (
                            <RoomList
                              newRoom={() => {
                                setShowRoomOptions(true);
                                setShowRoomPopup(roomPopups.NONE);
                              }}
                              setOpenedInvites={setOpenedInvites}
                              setMedia={(v) => setMedia({ ...media, show: v })}
                            />
                          )}
                        </div>
                      )}
                      {media.show && (
                        <YtPlayer
                          setMedia={(v) => setMedia({ ...media, show: v })}
                        />
                      )}
                      <Inventory />
                    </div>
                  )}
                </div>
                <div className={styles.playerListAndChat}>
                  <div style={{ minHeight: 525 }}>
                    <Settings showAd={props.ad} />
                    <PlayerList
                      setSelectedOption={(key, data) => {
                        setSelectedOption({ key, data });
                      }}
                    />
                    <Chat
                      selectedOption={selectedOption}
                      currentRoom={currentRoom}
                      tab={chatTab}
                    />
                  </div>
                </div>
              </div>
            )}
          </BrowserView>
          <MobileView>
            <div className={styles.mobile} style={{ height: height }}>
              <div className={styles.mobileContent}>
                {connected && (
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}
                    >
                      {currentMobileView != 1 && (
                        <PlayerList
                          mobile={true}
                          mini={true}
                          setSelectedOption={(key, data) => {
                            setSelectedOption({ key, data });
                          }}
                        />
                      )}
                      <Settings mini={true} />
                    </div>
                    {currentMobileView == 0 && (
                      <div>
                        {!inGame && (
                          <div>
                            {openedInvites && (
                              <div>
                                <div>
                                  <h3>Pozivi: </h3>
                                  <ButtonToolbar>
                                    <ButtonGroup size="lg">
                                      <Button
                                        appearance={
                                          invitesOn ? 'primary' : 'default'
                                        }
                                        onClick={() => {
                                          setInvitesOn(true);
                                        }}
                                      >
                                        Uključeni
                                      </Button>
                                      <Button
                                        appearance={
                                          !invitesOn ? 'primary' : 'default'
                                        }
                                        onClick={() => {
                                          setInvitesOn(false);
                                        }}
                                      >
                                        Isključeni
                                      </Button>
                                    </ButtonGroup>
                                  </ButtonToolbar>
                                </div>
                                {!!invites.length && (
                                  <div style={{ width: width }}>
                                    {invites.map((inv) => {
                                      return (
                                        <div
                                          style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-around',
                                          }}
                                        >
                                          <img src={inv.by.picture} />
                                          <p>{inv.by.name} vas poziva u sobu</p>
                                          <Button
                                            onClick={() => {
                                              socket.emit(
                                                'joinRoom',
                                                inv.to.roomId
                                              );
                                              setOpenedInvites(false);
                                            }}
                                            color="green"
                                          >
                                            Prihvati
                                          </Button>
                                          <Button
                                            onClick={() => {
                                              removeInvite(inv);
                                              setOpenedInvites(false);
                                            }}
                                            color="red"
                                          >
                                            Odbij
                                          </Button>
                                        </div>
                                      );
                                    })}
                                  </div>
                                )}
                                {!invites.length && (
                                  <p>Nemate poziva, bez brige, bit će</p>
                                )}
                              </div>
                            )}
                            {!openedInvites && (
                              <div>
                                {showRoomOptions && (
                                  <NewRoom
                                    mobile={true}
                                    cancelRoomOptions={() =>
                                      setShowRoomOptions(false)
                                    }
                                    createRoom={createRoom}
                                    popup={showRoomPopup}
                                    exitRoom={exitToLobby}
                                    update={update}
                                  />
                                )}
                                {!showRoomOptions && (
                                  <RoomList
                                    newRoom={() => {
                                      setShowRoomOptions(true);
                                      setShowRoomPopup(roomPopups.NONE);
                                    }}
                                    setOpenedInvites={setOpenedInvites}
                                    mobile={true}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        )}
                        {inGame && (
                          <div>
                            <Game
                              update={update}
                              socket={socket}
                              mobile={true}
                              game="cnljs"
                            />
                          </div>
                        )}
                      </div>
                    )}
                    {currentMobileView == 1 && (
                      <div>
                        <PlayerList
                          mobile={true}
                          setSelectedOption={(key, data) => {
                            console.log('clicked listed item');
                            setMobileView(2);
                            setTimeout(() => {
                              setSelectedOption({ key, data });
                            }, 300);
                          }}
                        />
                      </div>
                    )}
                    {currentMobileView == 2 && (
                      <div>
                        <Chat
                          selectedOption={selectedOption}
                          currentRoom={currentRoom}
                          mobile={true}
                          tab={chatTab}
                        />
                      </div>
                    )}
                  </div>
                )}
                {!connected && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      src="/images/covercroped.png"
                      style={{ borderRadius: '0 0 10px 10px', width: '100vw' }}
                    />
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            style={{
                              maxWidth: '90vw',
                              textAlign: 'center',
                              background: 'lightblue',
                              border: '1px solid',
                              borderRadius: '0 0 10px 10px',
                              padding: 10,
                              marginBottom: 3,
                            }}
                          >
                            Za igranje je potreban facebook račun, kliknite na
                            gumb za ulaz. Potrebne su nam minimalne informacije
                            najviše iz razloga da se ne morate registrirati i
                            ručno upisivati podatke.
                          </p>
                          <FacebookLogin
                            appId={process.env.REACT_APP_FB_APP_ID}
                            autoLoad={true}
                            fields="name,email,picture"
                            scope={scope}
                            callback={onLogin}
                            version="v14.0"
                            disableMobileRedirect={true}
                          />
                        </div>
                        {context.errors && context.errors.length > 0 && (
                          <Help />
                        )}
                        {
                          <p>
                            igrajte na{' '}
                            <a href="https://cnljs.com">
                              glavnoj stranici (cnljs.com)
                            </a>{' '}
                            ako vam se ovdje igra ne ucitava
                          </p>
                        }
                      </div>
                    </div>
                    <p>Podrži razvoj igre koristeći jednu od usluga</p>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        src="/images/patreon.png"
                        style={{ maxWidth: '50vw' }}
                      />
                      <img
                        src="/images/logo_paysafecard.png"
                        style={{ maxWidth: '90vw' }}
                      />
                      <img
                        src="/images/xbon.png"
                        style={{ maxWidth: '50vw' }}
                      />
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <p>Developed by:</p>
                      <img
                        src="images/proclive.png"
                        style={{ maxWidth: '100vw' }}
                      />
                      <a href="/TAC">Terms and Conditions</a> and{' '}
                      <a href="https://proclive.io/tosnpp/" target="_blank">
                        Privacy Policy
                      </a>
                    </div>
                  </div>
                )}
              </div>
              {currentMobileView == 0 && !openedInvites && !inGame && (
                <div className={styles.mobileFooter}>
                  <div
                    className={styles.mobileNav}
                    onClick={() => setShowRoomOptions(true)}
                  >
                    Napravi Sobu
                  </div>
                  <div
                    className={styles.mobileNav}
                    onClick={() => setRoomList()}
                  >
                    Prikaz: {roomFilterNames[cntx.roomListFilter]}
                  </div>
                  <div
                    className={styles.mobileNav}
                    onClick={() =>
                      openedInvites
                        ? setOpenedInvites(false)
                        : setOpenedInvites(true)
                    }
                  >
                    Pozivi
                  </div>
                  <div
                    className={styles.mobileNav}
                    onClick={() => {
                      setMobileView(2);
                      setChatTab(0);
                    }}
                  >
                    Chat
                  </div>
                </div>
              )}
              {currentMobileView == 0 && openedInvites && !inGame && (
                <div className={styles.mobileFooter}>
                  <div
                    className={styles.mobileNav}
                    onClick={() =>
                      openedInvites
                        ? setOpenedInvites(false)
                        : setOpenedInvites(true)
                    }
                  >
                    Zatvori
                  </div>
                </div>
              )}
              {currentMobileView == 0 && inGame && (
                <div className={styles.mobileFooter}>
                  <div
                    className={styles.mobileNav}
                    onClick={() => {
                      socket.emit('joinRoom', 0);
                    }}
                  >
                    Izađi
                  </div>
                  <div
                    className={styles.mobileNav}
                    onClick={() => setMobileView(1)}
                  >
                    Igrači
                  </div>
                  <div
                    className={styles.mobileNav}
                    onClick={() => {
                      setMobileView(2);
                      setChatTab(0);
                    }}
                  >
                    Chat
                  </div>
                </div>
              )}
              {currentMobileView == 1 && (
                <div className={styles.mobileFooter}>
                  <div
                    className={styles.mobileNav}
                    onClick={() => setMobileView(0)}
                  >
                    {inGame ? 'Igra' : 'Sobe'}
                  </div>
                  {context.user.premium ? null : (
                    <div
                      className={styles.mobileNav}
                      onClick={() => showInternalAd('premiumPlayerList')}
                    >
                      Prikaz
                    </div>
                  )}
                  <div
                    className={styles.mobileNav}
                    onClick={() => setMobileView(2)}
                  >
                    Chat
                  </div>
                </div>
              )}
              {currentMobileView == 2 && (
                <div className={styles.mobileFooter}>
                  <div
                    className={styles.mobileNav}
                    onClick={() => setMobileView(1)}
                  >
                    Igrači
                    {context.playerCount ? '(' + context.playerCount + ')' : ''}
                  </div>
                  <div
                    className={styles.mobileNav}
                    onClick={() => (chatTab ? setChatTab(0) : setChatTab(1))}
                  >
                    {!chatTab
                      ? 'Privatne Poruke' +
                        (context.unreadChats ? ` (${context.unreadChats})` : '')
                      : 'Javni Chat'}
                  </div>
                  <div
                    className={styles.mobileNav}
                    onClick={() => setMobileView(0)}
                  >
                    {inGame ? 'Igra' : 'Sobe'}
                  </div>
                </div>
              )}
            </div>
          </MobileView>
          <Modal
            show={!!rewards && !props.ad && rewards.length}
            size={mobile ? 'xs' : 'md'}
            onHide={() => {
              setRewards(false);
            }}
          >
            <Modal.Header>
              <Modal.Title>Nagrade</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {rewards &&
                rewards.length &&
                rewards.map((rwrd, i) => {
                  let endsIn = rwrd.ends ? getTimeTillDate(rwrd.ends) : false;
                  //console.log("ends in", getTimeTillDate(rwrd.ends));
                  return (
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          background: '#EEEEEE',
                          justifyContent: 'space-between',
                          borderRadius: 15,
                          padding: 10,
                          margin: 10,
                        }}
                      >
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <h4>{rwrd.title}</h4>
                          <p>{rwrd.description}</p>
                          {endsIn && (
                            <p>
                              vrijedi jos:{' '}
                              {endsIn.days ? endsIn.days + ' dana' : ''}{' '}
                              {endsIn.hours ? endsIn.hours + '  sati' : ''}{' '}
                              {endsIn.minutes} minute
                            </p>
                          )}
                          {rwrd.id == 3 && rwrd.data && (
                            <div>
                              {rwrd.data.map((u) => {
                                return (
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      width: 50,
                                    }}
                                  >
                                    <p>{u.user.name}</p>
                                    <img src={u.user.picture} />
                                    <p>{u.bonus} Dukat</p>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                          {rwrd.id == 5 && (
                            <div>
                              <img
                                src="/assets/Lik_snail_crveni.png"
                                style={{ width: 50 }}
                              />
                              <img
                                src="/assets/Lik_snail_plavi.png"
                                style={{ width: 70 }}
                              />
                              <img
                                src="/assets/Lik_snail_zuti.png"
                                style={{ width: 80 }}
                              />
                              <img
                                src="/assets/Lik_snail_zeleni.png"
                                style={{ width: 70 }}
                              />
                              <Input
                                placeholder="kod koji vam je poslao Pjero"
                                onChange={setRewardCode}
                              />
                              <a
                                href="https://www.instagram.com/cantspelllastname"
                                target="_blank"
                              >
                                Zaprati ilustratora Pjeru na instacu
                              </a>
                            </div>
                          )}
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Icon
                            icon="gift"
                            size="3x"
                            style={{ color: 'gold' }}
                          />
                          <Button
                            onClick={() => {
                              rewardsService
                                .claim(rwrd.id, rewardCode)
                                .then((r) => {
                                  console.log('reward response', r);
                                  if (r?.type == 'error') {
                                    open('error', r.msg);
                                  }
                                  getRewards();
                                  socket.emit('updatePlayer');
                                  setTimeout(() => {
                                    socket.emit('whoAmI');
                                  }, 2000);
                                })
                                .catch((e) => {
                                  console.log(e);
                                  setRewards(false);
                                });
                            }}
                            appearance="primary"
                          >
                            Pokupi
                          </Button>
                        </div>
                        {rwrd.error && <div>{JSON.stringify(rwrd.error)}</div>}
                      </div>
                    </div>
                  );
                })}
              <Divider />
              <div>
                <div>
                  <div>
                    Podrži nas preko patreona kako bi mogli ubacivati više novih
                    stvari!{' '}
                    <a target="_blank" href="https://www.patreon.com/iggyZiggy">
                      <Button color="orange">Patreon</Button>
                    </a>
                  </div>
                  <h4>Promjene od 28.02.2021:</h4>
                  <p>ispunjavanje anketa za dukate</p>
                  <p>
                    ban stranica sa opcijama za gledanje reklama i ispunjavanjem
                    anketa
                  </p>
                  <h4>Promjene od 25.02.2021:</h4>
                  <p>- paydafecard opcije</p>
                  <p>- nova nagradna figura</p>
                  <h4>Promjene od 15.02.2021:</h4>
                  <p>
                    - smrtonosna šorka od MK zasvira kad krene smrtonosni krug
                  </p>
                  <h4>Promjene od 13.02.2021:</h4>
                  <p>- slanje poklona</p>
                  <p>- popravak visine elemenata na mobilnoj verziji</p>
                  <h4>Promjene od 12.02.2021:</h4>
                  <p>- [BUG FIX] greška kod kupovine zadnje figure</p>
                  <p>- provjera xbonova i dodavanje dukata automatizirano</p>
                  <p>- ubačen još jedan zvuk na zahtijev igrača</p>
                  <h4>Promjene od 09.02.2021:</h4>
                  <p>- [NOVO] 6 novih figura</p>
                  <p>
                    - kodovi za nagrade, moguće je uz pomoć kodova koje dobijete
                    od neke treće strane, kao recimo našeg ilustratora{' '}
                    <a
                      href="https://www.instagram.com/cantspelllastname"
                      target="_blank"
                    >
                      Pjere s instragrama
                    </a>{' '}
                    dobiti kodove za figure koje nam je on nacrtao
                  </p>
                  <p>- upisivanje kodova za x-bon</p>
                  <p>- u dućanu primjer figura svih boja</p>
                  <h4>Promjene od 31.01.2021:</h4>
                  <p>
                    - [BUG FIX] kada se odigraju 2 smrtonosne za redom, figure
                    ulaze u krivi krug
                  </p>
                  <p>
                    - kada se namjeste figure jednom, ostaju tako namještene za
                    sljedeće igre, ne treba ih ponovno namještati
                  </p>
                  <p>
                    - ako vam pukne veza, vrati vas natrag u igru, na mobitelima
                    je to ako odete na drugu app recimo, kad se vratite na igru,
                    ne baci vas u predsoblje nego natrag u sobu
                  </p>
                  <p>- promjena imena u dućanu</p>
                  <h4>Promjene od 28.01.2021:</h4>
                  <p>
                    - [BUG FIX] Kopiranje youtube linka u chat u slučaju da nije
                    upaljena media
                  </p>
                  <p>
                    - Naslovna stranica, da nije samo login gumb, ubaceno par
                    slika i teksta
                  </p>
                  <h4>Promjene od 26.01.2021:</h4>
                  <p>- Popravljen prikaz privatnih poruka na računalima</p>
                  <p>
                    - Media pjesme nakon što odsviraju padaju na dno liste
                    umjesto na vrijednost 0
                  </p>
                  <p>- Terms & Conditions</p>
                  <h4>Promjene od 22.01.2021:</h4>
                  <p>
                    - Ubačeno brojanje dukata od reklama koje su pogledali ljudi
                    koje ste vi pozvali na igru
                  </p>
                  <p>- Omogučena kupnja proširenja blok liste</p>
                  <p>
                    - Ispravljena greška u bazi zbog koje su se duplali profili,
                    ovo je utjecalo na neke igrače tako da bi im se krivo
                    prikazivalo stanje dukata i/ili kupljenih stvari. Ispravkom
                    su dodjeljeni dukati sa drugog profila minus početnih 100
                    kao i ostale stvari, kupljene figure, zvukovi...
                  </p>
                  <p>- Pozadina proširena preko cijelog ekrana</p>
                  <p>
                    - X gumbi za zatvaranje na nekim popup prozorima nisu
                    funkcionirali, sada funkcioniraju
                  </p>
                  <p>
                    - traju pregovori sa paysafecard i x-bon za uplate bez
                    kartica
                  </p>
                  <p>
                    - media opcija umjesto popisa soba za ljude koji vise u
                    predsoblju i ne igraju, youtube linkovi se prebacuju na
                    zajedničku playlistu
                  </p>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => setRewards(false)} appearance="primary">
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={!!announcement}
            size={mobile ? 'xs' : 'md'}
            onHide={() => {
              setAnnouncement(false);
            }}
          >
            <Modal.Header>
              <Modal.Title>Obavijest</Modal.Title>
            </Modal.Header>
            <Modal.Body>{announcement}</Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => setAnnouncement(false)}
                appearance="primary"
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={openedInvites && isBrowser}
            onHide={() => setOpenedInvites(false)}
          >
            <Modal.Header>
              <Modal.Title>
                Pozivi
                <ButtonToolbar>
                  <ButtonGroup>
                    <Button
                      appearance={invitesOn ? 'primary' : 'default'}
                      onClick={() => {
                        setInvitesOn(true);
                      }}
                    >
                      Uključeni
                    </Button>
                    <Button
                      appearance={!invitesOn ? 'primary' : 'default'}
                      onClick={() => {
                        setInvitesOn(false);
                      }}
                    >
                      Isključeni
                    </Button>
                  </ButtonGroup>
                </ButtonToolbar>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {!!invites.length && (
                <div>
                  {invites.map((inv) => {
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-around',
                        }}
                      >
                        <img src={inv.by.picture} />
                        <p>{inv.by.name} vas poziva u sobu</p>
                        <Button
                          onClick={() => {
                            socket.emit('joinRoom', inv.to.roomId);
                            setOpenedInvites(false);
                          }}
                          color="green"
                        >
                          Prihvati
                        </Button>
                        <Button
                          onClick={() => {
                            removeInvite(inv);
                            setOpenedInvites(false);
                          }}
                          color="red"
                        >
                          Odbij
                        </Button>
                      </div>
                    );
                  })}
                </div>
              )}
              {!invites.length && <p>Nemate poziva, bez brige, bit će</p>}
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => setOpenedInvites(false)}
                appearance="primary"
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={modal.opened}
            onHide={() => {
              setModal({ opened: false });
            }}
            size={mobile ? 'xs' : 'md'}
          >
            <Modal.Header>
              <Modal.Title>{modal.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modal.description}</Modal.Body>
            <Modal.Footer>
              {modal.yes && (
                <Button
                  onClick={() => {
                    if (modal.yesCb) modal.yesCb();
                    setModal({ opened: false });
                  }}
                  appearance="primary"
                >
                  {modal.yes}
                </Button>
              )}
              {modal.no && (
                <Button
                  onClick={() => {
                    if (modal.noCb) modal.noCb();
                    setModal({ opened: false });
                  }}
                  color="red"
                >
                  {modal.no}
                </Button>
              )}
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </CentralContext.Consumer>
  );
};

export default Pages;
